import { createApp } from 'vue'
import App from './App.vue'
import {initRouter} from './router'
import { myCreateStore } from './store'

// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
//@ts-ignore忽略当前文件ts类型的检测否则有红色提示(打包会失败)
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
const store = myCreateStore();
console.log('store',store)

const router = initRouter(store);

app.use(router)
.use(ElementPlus,{locale:zhCn})
.mount('#app')
