import { createRouter, createWebHistory } from 'vue-router'

export function initRouter(store) {
  const routes = [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index.vue')
    },
    {
      path: '/',
      component: () => import('@/views/layout/index.vue'),
      children: [
        {
          path: '/',
          redirect: '/home'
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/home/index.vue')
        },
        {
          path: '/student',
          name: 'student', // 将父路由的名字移到这里
          component: () => import('@/views/student/index.vue'),
          meta: {
            name: '学生管理'
          },
          children: [
            {
              path: '',
              redirect:'/student/stu_list'
            },
            {
              path: '/student/stu_list',
              name: 'stu_list', // 给子路由指定一个名字
              component: () => import('@/views/student/modules/stu_list.vue')
            },
            {
              path: '/student/add_stu_list',
              name: 'add_stu_list',
              component: () => import('@/views/student/modules/add_stu_list.vue'),
              meta: {
                name: '添加学生',
                parentUrl:'/student/stu_list'
              }
            },
            {
              path: '/student/update_stu',
              name: 'update_stu',
              component: () => import('@/views/student/modules/update_stu.vue'),
              meta: {
                name: '修改学生信息',
                parentUrl:'/student/stu_list'
              }
            },
            {
              path: '/student/look_stu_exam',
              name: 'look_stu_exam',
              component: () => import('@/views/student/modules/look_stu_exam.vue'),
              meta: {
                name: '查看考试记录',
                parentUrl:'/student/stu_list'
              }
            },
            {
              path: '/student/print_exam',
              name: 'print_exam',
              component: () => import('@/views/student/modules/print_exam.vue'),
              meta: {
                name: '打印试卷',
                parentUrl:'/student/stu_list'
              }
            },
          ]
        },
        {
          path: '/test_paper',
          name: 'test_paper',
          component: () => import('@/views/testPaper/index.vue')
        },
        {
          path: '/learning',
          name: 'learning',
          component: () => import('@/views/learning/index.vue')
        }
      ]
    }

    // {
    //     path: '/404',
    //     component: () => import('@/views/404/index.vue'),
    //     name: '404'
    // },
    // {
    //     // 任意路由
    //     path: '/:pathMatch(.*)*',
    //     redirect: '/404',
    //     name: 'NotFound'
    // }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.beforeEach((to, from, next) => {
    // 在这里进行权限验证、登录判断等操作
    let token = store.getters['isAuthenticated']
    console.log('路由拦截', token, to.path)
    if (!token) {
      console.log('跳转至登录')
      // 如果当前路由需要身份验证，并且用户未认证，则重定向到登录页面
      // 将用户重定向到登录页面
      if (to.path === '/login') {
        next();
      } else {
        next('/login');
      }
    } else if (to.path === '/login' && token) {
      // 如果用户已经认证，且试图访问登录页面，则重定向到上一个页面
      console.log('from',from)
      next(from.path); // 重定向到上一个页面
    } else {
      // 如果用户已认证或者当前路由不需要身份验证，则继续导航
      next();
    }
    // next()
  })

  return router

}
