import { createStore } from 'vuex'
import auth from './auth'; // 引入 auth 模块

const myCreateStore = () =>{
  return createStore({
    modules:{
      auth
    }
  })
}

export {
  myCreateStore
}
