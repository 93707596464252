// auth.js

const state = {
    token: localStorage.getItem('token') || ''
  };
  
  const mutations = {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    clearToken(state) {
      state.token = '';
      localStorage.removeItem('token');
    }
  };
  
  const actions = {
   
  };
  
  const getters = {
    isAuthenticated: state => state.token
  };
  
  export default {
    state,
    mutations,
    actions,
    getters
  };
  